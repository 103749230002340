import { template as template_0562d4eb74834c3a93070079522e137e } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_0562d4eb74834c3a93070079522e137e(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
