import { template as template_07281e7fba1441a59350c6a0edefcd87 } from "@ember/template-compiler";
const FKLabel = template_07281e7fba1441a59350c6a0edefcd87(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
