import { template as template_1283ea56fdf24d8f9ee2fc0b07e602bc } from "@ember/template-compiler";
const FKControlMenuContainer = template_1283ea56fdf24d8f9ee2fc0b07e602bc(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
