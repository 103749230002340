import { template as template_8303703972b144e5a6d0c182d0e7a0da } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
import Logo from "./logo";
const HomeLogoContents = template_8303703972b144e5a6d0c182d0e7a0da(`
  {{#if @minimized}}
    {{#if @logoSmallUrl}}
      <Logo
        @key="logo-small"
        @url={{@logoSmallUrl}}
        @title={{@title}}
        @darkUrl={{@logoSmallUrlDark}}
      />
    {{else}}
      {{icon "house"}}
    {{/if}}
  {{else if @showMobileLogo}}
    <Logo
      @key="logo-mobile"
      @url={{@mobileLogoUrl}}
      @title={{@title}}
      @darkUrl={{@mobileLogoUrlDark}}
    />
  {{else if @logoUrl}}
    <Logo
      @key="logo-big"
      @url={{@logoUrl}}
      @title={{@title}}
      @darkUrl={{@logoUrlDark}}
    />
  {{else}}
    <h1 id="site-text-logo" class="text-logo">
      {{@title}}
    </h1>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default HomeLogoContents;
