import { template as template_a646dc0c9c5f4702a8016ae9e2c01fe8 } from "@ember/template-compiler";
const SidebarSectionMessage = template_a646dc0c9c5f4702a8016ae9e2c01fe8(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
